import React, { useEffect } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { HomeActions } from '@adapters/redux/reducer/home';
import PageLoader from '@components/Loader/PageLoader';
import { convertHexToRGBA, fullBackendPath, kebabCase } from '@core/helpers';
import ErrorBoundary from '@components/ErrorBoundary';
import { useTranslation } from 'react-i18next';

const EventDetail = (props) => {
  const { t, i18n } = useTranslation();
  const { events, eventDetail, getPageDetail, loading } = props;

  const { state } = useLocation();
  const { name } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (state?.id) {
      getPageDetail({
        url: `meeting-event/${state.id}`,
        page: 'eventDetail',
      });
    } else {
      const event = events?.find((off) => {
        const kbName = kebabCase(off.name);
        return name === kbName;
      });
      if (event) {
        getPageDetail({
          url: `meeting-event/${event.id}`,
          page: 'eventDetail',
        });
      } else if (events?.[0]) {
        getPageDetail({
          url: `meeting-event/${events[0].id}`,
          page: 'eventDetail',
        });
      } else {
        navigate('/page-not-found', { replace: true });
      }
    }
  }, [state, name]);

  return (
    <>
      <main>
        <section
          className="breadcrumb-area breadcrumb-area-detail d-flex align-items-center background-header"
          style={{
            background: `url("${fullBackendPath(
              eventDetail?.meeting_background?.path
            )}")`,
          }}
        >
          <div className="container">
            <div className="row align-items-center">
              <div className="col-xl-12 col-lg-12">
                <div className="breadcrumb-wrap text-center">
                  <div className="breadcrumb-title breadcrumb-title-white">
                    <h2>
                      {i18n.language === 'en'
                        ? eventDetail?.name || eventDetail?.name_vi
                        : eventDetail?.name_vi || eventDetail?.name}
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          className="about-area about-p pt-20 pb-20 p-relative fix"
          style={{ background: convertHexToRGBA('#ffffff', 0.8) }}
        >
          <div className="container">
            <div
              className="about-content s-about-content wow fadeInRight animated"
              data-animation="fadeInRight"
              data-delay=".4s"
            >
              <div
                className="ck-content"
                dangerouslySetInnerHTML={{
                  __html:
                    i18n.language === 'en'
                      ? eventDetail?.describe || eventDetail?.describe_vi
                      : eventDetail?.describe_vi || eventDetail?.describe,
                }}
              />
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

const mapStateToProps = (state) => ({
  loading: state.home.loadingPageDetail,
  events: state.home.events,
  eventDetail: state.home.eventDetail,
});

const mapDispatchToProps = (dispatch) => ({
  getPageDetail: (params) => dispatch(HomeActions.getPageDetail(params)),
});

const withErrorBoundary = (props) => (
  <ErrorBoundary>
    <EventDetail {...props} />
  </ErrorBoundary>
);

export default connect(mapStateToProps, mapDispatchToProps)(withErrorBoundary);
