import { Link } from 'react-router-dom';
import BGImg from '@assets/img/bg/bdrc-bg.jpg';
import React, { useState, useEffect } from 'react';
import FsLightbox from 'fslightbox-react';
import ErrorBoundary from '@components/ErrorBoundary';
import { connect } from 'react-redux';
import { convertHexToRGBA, fullBackendPath } from '@core/helpers';
import { useTranslation } from 'react-i18next';
import GalleryBG from '../../assets/img/bg/gallery_bg.jpg';

const Gallery = (props) => {
  const { t, i18n } = useTranslation();
  const { intro_section, galleries } = props;
  const { intro_image1 } = intro_section;

  const [activeImage, setActiveImage] = useState(1);
  const [imagesLightbox, setImagesLightbox] = useState(
    (galleries[0]?.images || []).map((image) => fullBackendPath(image.path))
  );

  const [toggler, setToggler] = useState(false);

  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    setImagesLightbox(
      (galleries[activeTab]?.images || []).map((image) =>
        fullBackendPath(image.path)
      )
    );
  }, [activeTab]);

  return (
    <>
      <main>
        <section
          className="breadcrumb-area breadcrumb-area-detail d-flex align-items-center background-header"
          style={{
            background: `url("${GalleryBG}")`,
          }}
        >
          <div className="container">
            <div className="row align-items-center">
              <div className="col-xl-12 col-lg-12">
                <div className="breadcrumb-wrap text-center">
                  <div className="breadcrumb-title">
                    <h2>{t('Gallery')}</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          className="profile fix pt-50 pb-50 gallery-mobile"
          style={{ background: convertHexToRGBA('#ffffff', 0.8) }}
        >
          <div className="container-fluid">
            <div className="row">
              <div className="col-xl-12 col-lg-12">
                <div className="text-center mb-50">
                  <div className="button-group">
                    {galleries.map(({ id, name, name_vi }, index) => {
                      return (
                        <button
                          key={`item-${index}`}
                          className={`${activeTab === index && 'active'}`}
                          onClick={() => setActiveTab(index)}
                        >
                          {i18n.language === 'en'
                            ? name || name_vi
                            : name_vi || name}
                        </button>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="row">
                  {galleries?.[activeTab]?.images?.map((image, index) => {
                    return (
                      <div
                        className="col-sm-6 col-md-4 col-lg-3 mb-3 square-div"
                        style={{ position: 'relative', cursor: 'pointer' }}
                      >
                        <div className="image-container">
                          <figure
                            className="gallery-image"
                            onClick={() => {
                              setActiveImage(index + 1);
                              setToggler(!toggler);
                            }}
                          >
                            <img
                              width={'100%'}
                              height={'100%'}
                              src={fullBackendPath(image.path)}
                              alt="img"
                              className="img"
                              style={{ objectFit: 'cover' }}
                            />
                          </figure>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <FsLightbox
        toggler={toggler}
        sources={imagesLightbox}
        slide={activeImage}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  intro_section: state.home.intro_section,
  galleries: state.home.galleries,
});

const mapDispatchToProps = () => ({});

const withErrorBoundary = (props) => (
  <ErrorBoundary>
    <Gallery {...props} />
  </ErrorBoundary>
);

export default connect(mapStateToProps, mapDispatchToProps)(withErrorBoundary);
