import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEN from './locales/en.json';
import translationVI from './locales/vi.json';

const resources = {
  en: {
    translation: translationEN,
  },
  vi: {
    translation: translationVI,
  },
};

i18n.use(initReactI18next).init({
  fallbackLng: 'en',
  lng: 'en', // localStorage.getItem('language') || 'en',
  // debug: true,
  react: {
    useSuspense: true,
  },
  resources,
});

export default i18n;
