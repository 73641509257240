import { Link } from 'react-router-dom';
import React, { useState } from 'react';
import FsLightbox from 'fslightbox-react';
import { connect } from 'react-redux';
import { convertHexToRGBA, fullBackendPath, kebabCase } from '@core/helpers';
import ErrorBoundary from '@components/ErrorBoundary';
import { useTranslation } from 'react-i18next';
import DestinationBG from '../../../assets/img/bg/destination_bg.jpg';

const Destinations = (props) => {
  const { t, i18n } = useTranslation();
  const { destinations } = props;

  return (
    <main>
      <section
        className="breadcrumb-area breadcrumb-area-detail d-flex align-items-center background-header"
        style={{
          background: `url(${DestinationBG})`,
        }}
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-12 col-lg-12">
              <div className="breadcrumb-wrap text-center">
                <div className="breadcrumb-title">
                  <h2>{t('Destinations')}</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        id="services"
        className="services-area pt-90 pb-90"
        style={{ background: convertHexToRGBA('#ffffff', 0.8) }}
      >
        <div className="container">
          <div className="row justify-content-center">
            {destinations.map((des, index) => {
              const { id, name, name_vi, des_background } = des;

              return (
                <div key={id} className="col-xl-3 col-md-6 mb-4 px-2px">
                  <Link
                    to={`/destination-detail/${kebabCase(name)}`}
                    state={{ id }}
                    className="gallery-link popup-image"
                  >
                    <div className="single-services position-relative">
                      <div className="services-thumb">
                        <img
                          src={fullBackendPath(des_background?.path)}
                          alt="img"
                        />
                      </div>
                      <div className="services-thumb-hover">
                        <div className="border-1">
                          <div className="border-2 text-center">
                            {i18n.language === 'en'
                              ? name || name_vi
                              : name_vi || name}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </main>
  );
};

const mapStateToProps = (state) => ({
  destinations: state.home.destinations,
});

const mapDispatchToProps = () => ({});

const withErrorBoundary = (props) => (
  <ErrorBoundary>
    <Destinations {...props} />
  </ErrorBoundary>
);

export default connect(mapStateToProps, mapDispatchToProps)(withErrorBoundary);
