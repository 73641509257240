import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import ErrorBoundary from '@components/ErrorBoundary';
import { fullBackendPath } from '@core/helpers';
import { useTranslation } from 'react-i18next';

const PrivacyPolicy = (props) => {
  const { t, i18n } = useTranslation();
  const { intro_section } = props;
  const { intro_image1 } = intro_section;

  return (
    <>
      <main>
        <section
          className="breadcrumb-area breadcrumb-area-detail d-flex align-items-center background-header"
          style={{
            // background: `url("${fullBackendPath(intro_image1?.path)}")`,
            background: 'rgba(255, 255, 255, 0.8)',
          }}
        >
          <div className="container">
            <div className="row align-items-center">
              <div className="col-xl-12 col-lg-12">
                <div className="breadcrumb-wrap text-center">
                  <div className="breadcrumb-title">
                    <h2>Privacy Policy</h2>
                    {/* <div className="breadcrumb-wrap">
                      <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                          <li className="breadcrumb-item">
                            <Link to="/">Home</Link>
                          </li>
                          <li
                            className="breadcrumb-item active"
                            aria-current="page"
                          >
                            Privacy Policy
                          </li>
                        </ol>
                      </nav>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          id="contact"
          className="contact-area after-none contact-bg pt-120 pb-120 p-relative fix"
        >
          <div className="container">
            <div className="row justify-content-center align-items-center">
              <div className="col-12">
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Sit amet consectetur adipiscing elit. Et ligula ullamcorper
                  malesuada proin libero nunc consequat. Porta non pulvinar
                  neque laoreet suspendisse interdum consectetur libero. Ipsum
                  consequat nisl vel pretium lectus quam id. Vel turpis nunc
                  eget lorem dolor sed viverra. Enim nulla aliquet porttitor
                  lacus luctus accumsan tortor posuere ac. Vivamus at augue eget
                  arcu dictum varius duis at. Blandit aliquam etiam erat velit.
                  Et odio pellentesque diam volutpat commodo. Vel elit
                  scelerisque mauris pellentesque pulvinar pellentesque
                  habitant.
                </p>
                <p>
                  Faucibus nisl tincidunt eget nullam non nisi est sit amet. Id
                  donec ultrices tincidunt arcu non sodales. Sapien et ligula
                  ullamcorper malesuada. Cursus turpis massa tincidunt dui ut.
                  Id aliquet lectus proin nibh nisl condimentum id venenatis a.
                  Velit egestas dui id ornare arcu odio ut. Lobortis scelerisque
                  fermentum dui faucibus in ornare quam. Justo donec enim diam
                  vulputate ut pharetra. Libero nunc consequat interdum varius
                  sit. Felis bibendum ut tristique et egestas quis ipsum
                  suspendisse ultrices. Turpis egestas maecenas pharetra
                  convallis. Cras fermentum odio eu feugiat pretium nibh ipsum.
                  Pretium aenean pharetra magna ac. Est placerat in egestas erat
                  imperdiet sed euismod. Lorem ipsum dolor sit amet consectetur
                  adipiscing elit.
                </p>
                <p>
                  Fringilla urna porttitor rhoncus dolor purus non enim praesent
                  elementum. Enim nec dui nunc mattis enim ut tellus elementum
                  sagittis. Lectus quam id leo in. Nunc pulvinar sapien et
                  ligula ullamcorper malesuada proin libero nunc. Orci sagittis
                  eu volutpat odio facilisis mauris sit amet massa. Diam
                  vulputate ut pharetra sit. Aenean vel elit scelerisque mauris
                  pellentesque pulvinar pellentesque habitant. Sit amet dictum
                  sit amet. Eleifend donec pretium vulputate sapien. Non quam
                  lacus suspendisse faucibus interdum posuere. Urna id volutpat
                  lacus laoreet non.
                </p>
                <p>
                  Volutpat est velit egestas dui id ornare arcu odio. Sed
                  euismod nisi porta lorem mollis aliquam ut porttitor leo.
                  Velit euismod in pellentesque massa. Eu tincidunt tortor
                  aliquam nulla facilisi cras fermentum odio. Arcu dictum varius
                  duis at consectetur lorem donec massa sapien. Rhoncus aenean
                  vel elit scelerisque mauris pellentesque. Pellentesque elit
                  ullamcorper dignissim cras. Pellentesque eu tincidunt tortor
                  aliquam nulla facilisi cras fermentum odio. Suspendisse
                  faucibus interdum posuere lorem. Nisl condimentum id venenatis
                  a. A scelerisque purus semper eget. Gravida neque convallis a
                  cras semper auctor neque vitae. Purus in massa tempor nec
                  feugiat. Vitae ultricies leo integer malesuada nunc vel risus
                  commodo. Odio pellentesque diam volutpat commodo sed egestas
                  egestas fringilla.
                </p>
                <p>
                  Tempus imperdiet nulla malesuada pellentesque. Vitae nunc sed
                  velit dignissim sodales ut eu sem. Metus vulputate eu
                  scelerisque felis imperdiet proin fermentum. Adipiscing at in
                  tellus integer feugiat. Fringilla phasellus faucibus
                  scelerisque eleifend donec pretium vulputate. Velit laoreet id
                  donec ultrices tincidunt arcu non sodales. Eget sit amet
                  tellus cras adipiscing enim eu turpis. Erat imperdiet sed
                  euismod nisi porta lorem mollis aliquam ut. Amet purus gravida
                  quis blandit turpis. Risus nullam eget felis eget nunc
                  lobortis mattis aliquam faucibus. Ac auctor augue mauris augue
                  neque gravida in fermentum et. Fames ac turpis egestas
                  maecenas pharetra convallis. Diam maecenas sed enim ut sem
                  viverra. Et netus et malesuada fames ac turpis egestas sed
                  tempus. Diam quis enim lobortis scelerisque fermentum dui
                  faucibus in. Convallis tellus id interdum velit laoreet.
                  Consectetur adipiscing elit pellentesque habitant morbi
                  tristique senectus.
                </p>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

const mapStateToProps = (state) => ({
  intro_section: state.home.intro_section,
});

const mapDispatchToProps = () => ({});

const withErrorBoundary = (props) => (
  <ErrorBoundary>
    <PrivacyPolicy {...props} />
  </ErrorBoundary>
);

export default connect(mapStateToProps, mapDispatchToProps)(withErrorBoundary);
