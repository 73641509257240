import React, { useEffect, useRef, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';

import Navbar from '@components/Navbar';
import Footer from '@components/Footer';
import Home from '@components/Home';
import About from '@components/About';
import OurRooms from '@components/Rooms/OurRooms';
import RoomDetail from '@components/Rooms/RoomDetail';
import Gallery from '@components/Gallery';
import Faq from '@components/FAQ';
import Team from '@components/Pages/Team';
import TeamDetail from '@components/Pages/TeamDetail';
import Pricing from '@components/Pages/Pricing';
import Shop from '@components/Pages/Shop';
import ShopDetails from '@components/Pages/ShopDetails';
import Blogs from '@components/Blogs/Blogs';
import BlogDetail from '@components/Blogs/BlogDetail';
import Contact from '@components/Contact';
import PageNotFound from '@components/PageNotFound';
import Services from '@components/Facilities/Services';
import ServiceDetail from '@components/Facilities/ServiceDetail';
import Offers from '@components/Offers/Offers';
import OfferDetail from '@components/Offers/OfferDetail';
import Destinations from '@components/Destinations/Destinations';
import DestinationDetail from '@components/Destinations/DestinationDetail';
import Events from '@components/Events/Events';
import ErrorBoundary from '@components/ErrorBoundary';
import Dining from '@components/Dining/Dining';
import DiningDetail from '@components/Dining/DiningDetail';
import Experiences from '@components/Experiences/Experiences';
import ExperienceDetail from '@components/Experiences/ExperienceDetail';
import SpaWellness from '@components/SpaWellness/SpaWellness';
import SpaWellnessDetail from '@components/SpaWellness/SpaWellnessDetail';
import TermCondition from '@components/TermCondition';
import PrivacyPolicy from '@components/PrivacyPolicy';
import BookingGuide from '@components/BookingGuide';
import EventDetail from '@components/Events/EventDetail';

const Routing = () => {
  const [homepage, sethomepage] = useState(false);
  const location = useLocation();
  const ref = useRef(null);

  useEffect(() => {
    if (location.pathname === '/home-03' || location.pathname === '/home-04') {
      sethomepage(false);
    } else {
      sethomepage(true);
    }
  }, [location]);

  const [footerpage, setfooterpage] = useState(false);

  useEffect(() => {
    if (location.pathname === '/home-03') {
      setfooterpage(false);
    } else {
      setfooterpage(true);
    }
  }, [location]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {homepage && <Navbar />}
      <Routes>
        <Route path="/" element={<Home aboutRef={ref} />} />
        <Route path="/about" element={<About />} />
        <Route path="/rooms" element={<OurRooms />} />
        <Route path="/room-detail/:name" element={<RoomDetail />} />
        <Route path="/services" element={<Services />} />
        <Route path="/service-detail/:name" element={<ServiceDetail />} />
        <Route path="/offers" element={<Offers />} />
        <Route path="/offer-detail/:name" element={<OfferDetail />} />
        <Route path="/destinations" element={<Destinations />} />
        <Route
          path="/destination-detail/:name"
          element={<DestinationDetail />}
        />
        <Route path="/dining" element={<Dining />} />
        <Route path="/dining-detail/:name" element={<DiningDetail />} />
        <Route path="/spa-wellness" element={<SpaWellness />} />
        <Route
          path="/spa-wellness-detail/:name"
          element={<SpaWellnessDetail />}
        />
        <Route path="/experiences" element={<Experiences />} />
        <Route path="/experience-detail/:name" element={<ExperienceDetail />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/events" element={<Events />} />
        <Route path="/event-detail/:name" element={<EventDetail />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/booking-guide" element={<BookingGuide />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/term-conditions" element={<TermCondition />} />
        <Route path="/page-not-found" element={<PageNotFound />} />
        <Route path="/*" element={<PageNotFound />} />
      </Routes>
      {footerpage && <Footer aboutRef={ref} />}
    </>
  );
};

export default Routing;
