import axios, { AxiosInstance } from 'axios';

class ApiService {
  TIMEOUT = 20000;

  INSTANCE: AxiosInstance = undefined;

  TOKEN = '';

  Interceptors = [];

  constructor() {
    if (!this.INSTANCE) {
      this.INSTANCE = axios.create({
        baseURL: process.env.BASE_URL_API,
        timeout: this.TIMEOUT,
      });
    }
  }

  addAuthorizationHeader = (token: string) => {
    token && (this.TOKEN = token);
    const interceptorId = this.INSTANCE.interceptors.request.use((config) => {
      config.headers.Authorization = `Bearer ${token}`;
      return config;
    });
    this.Interceptors.push(interceptorId);
    return interceptorId;
  };

  removeAllInterceptor = () => {
    this.Interceptors.map((interceptorId) =>
      this.INSTANCE.interceptors.request.eject(interceptorId)
    );
  };

  fetchPageData = async (url) => {
    const response = await this.INSTANCE.get(url);
    return response?.data;
  };

  postRequest = async (url, params) => {
    try {
      const response = await this.INSTANCE.post(url, params);
      return response;
    } catch (error) {
      throw {
        message: error?.response?.data?.error || 'Error, please try again',
      };
    }
  };

  subscribe = async (params) => {
    try {
      const response = await this.INSTANCE.post('subscribe', params);
      return response;
    } catch (error) {
      throw {
        message:
          error?.response?.data?.error || 'Subscribe error, please try again',
      };
    }
  };

  getInTouch = async (params) => {
    try {
      const response = await this.INSTANCE.post('get-in-touch', params);
      return response;
    } catch (error) {
      throw {
        message:
          error?.response?.data?.error || 'Subscribe error, please try again',
      };
    }
  };
}

export default new ApiService();
