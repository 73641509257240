// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import { FAKE_DATA } from '@services/constant';
import { createActions, createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';
/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  getPagesData: [],
  getPagesDataSuccess: ['payload'],
  getPagesDataFailure: ['error'],

  subscribeEmail: ['params'],
  subscribeEmailSuccess: ['payload'],
  subscribeEmailFailure: ['error'],

  getInTouch: ['params'],
  getInTouchSuccess: ['payload'],
  getInTouchFailure: ['error'],

  getPageDetail: ['params'],
  getPageDetailSuccess: ['payload'],
  getPageDetailFailure: ['error'],
});

export const INITIAL_STATE = Immutable({
  homePage: FAKE_DATA.homePage,
  aboutUs: FAKE_DATA.aboutUs,
  ownerInfo: FAKE_DATA.ownerInfo,
  contactUs: FAKE_DATA.contactUs,
  rooms: FAKE_DATA.rooms || [],
  roomDetail: FAKE_DATA.roomDetail || {},
  services: FAKE_DATA.services || [],
  serviceDetail: FAKE_DATA.serviceDetail || {},
  offers: FAKE_DATA.offers || [],
  offerDetail: FAKE_DATA.offerDetail || {},
  destinations: FAKE_DATA.destinations || [],
  destinationDetail: FAKE_DATA.destinationDetail || {},
  spas: FAKE_DATA.spas || [],
  spaDetail: FAKE_DATA.spaDetail || {},
  dinnings: FAKE_DATA.dinnings || [],
  dinningDetail: FAKE_DATA.dinningDetail || {},
  experiences: FAKE_DATA.experiences || [],
  experienceDetail: FAKE_DATA.experienceDetail || {},
  events: FAKE_DATA.events,
  comments: FAKE_DATA.comments || [],
  accounts: [],
  galleries: FAKE_DATA.galleries || {},
  intro_section: {},
  section1: {},
  section2: {},
  section3: {},
  video_banner: {},
  subscribe_section: {},
  appReady: false,
  error: null,

  sendingSubscribe: false,
  subscribeNoti: null,

  sendingGetInTouch: false,
  getInTouchNoti: null,

  loadingPageDetail: false,
});

const getPages = (state = INITIAL_STATE) => {
  return { ...state };
};

const getPagesSuccess = (state = INITIAL_STATE, { payload }) => {
  return { ...state, ...payload };
};

const getPagesFailure = (state = INITIAL_STATE, { error }) => {
  return { ...state, error };
};

const subscribeEmail = (state = INITIAL_STATE) => {
  return { ...state, sendingSubscribe: true, subscribeNoti: null };
};

const subscribeEmailSuccess = (state = INITIAL_STATE, { payload }) => {
  return { ...state, sendingSubscribe: false, subscribeNoti: payload };
};

const subscribeEmailFailure = (state = INITIAL_STATE, { error }) => {
  return { ...state, sendingSubscribe: false, subscribeNoti: error };
};

const getInTouch = (state = INITIAL_STATE) => {
  return { ...state, sendingGetInTouch: true, subscribeNoti: null };
};

const getInTouchSuccess = (state = INITIAL_STATE, { payload }) => {
  return { ...state, sendingGetInTouch: false, getInTouchNoti: payload };
};

const getInTouchFailure = (state = INITIAL_STATE, { error }) => {
  return { ...state, sendingGetInTouch: false, getInTouchNoti: error };
};

const getPageDetail = (state = INITIAL_STATE) => {
  return { ...state, loadingPageDetail: true };
};

const getPageDetailSuccess = (state = INITIAL_STATE, { payload }) => {
  return { ...state, ...payload, loadingPageDetail: false };
};

const getPageDetailFailure = (state = INITIAL_STATE, { error }) => {
  return { ...state, loadingPageDetail: false };
};

export const HomeTypes = Types;

export const homeReducer = createReducer(INITIAL_STATE, {
  [Types.GET_PAGES_DATA]: getPages,
  [Types.GET_PAGES_DATA_SUCCESS]: getPagesSuccess,
  [Types.GET_PAGES_DATA_FAILURE]: getPagesFailure,

  [Types.SUBSCRIBE_EMAIL]: subscribeEmail,
  [Types.SUBSCRIBE_EMAIL_SUCCESS]: subscribeEmailSuccess,
  [Types.SUBSCRIBE_EMAIL_FAILURE]: subscribeEmailFailure,

  [Types.GET_IN_TOUCH]: getInTouch,
  [Types.GET_IN_TOUCH_SUCCESS]: getInTouchSuccess,
  [Types.GET_IN_TOUCH_FAILURE]: getInTouchFailure,

  [Types.GET_PAGE_DETAIL]: getPageDetail,
  [Types.GET_PAGE_DETAIL_SUCCESS]: getPageDetailSuccess,
  [Types.GET_PAGE_DETAIL_FAILURE]: getPageDetailFailure,
});

export const HomeActions = Creators;
