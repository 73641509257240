import * as React from 'react';

const ZaloFullSize = (props) => (
  <svg
    viewBox="32.711 -0.468 85.995 104.802"
    width={props.width}
    height={props.height}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g transform="matrix(0.183153, 0, 0, 0.194157, -15.707024, -52.688427)">
      <g transform="translate(0.000000,511.000000) scale(0.100000,-0.100000)">
        <path
          fill={props.color}
          d="M3150.9,2108.6l5.9-312.9l1188.1,2c653.5,0,1261.4,7.9,1352.5,15.8c89.1,5.9,162.4,5.9,162.4-2c-2-9.9-651.5-764.4-1445.6-1679.2L2968.7-1531l5.9-306.9l4-306.9h2019.8h2019.8l5.9,332.7l4,330.7H5853.9c-645.5,0-1338.6-9.9-1536.7-21.8c-200-11.9-364.4-17.8-368.3-13.8c-4,3.9,87.1,112.9,202,243.6c114.8,130.7,772.3,895.1,1459.4,1699l1249.5,1461.4l13.9,182.2c7.9,101,7.9,219.8,2,267.3l-9.9,83.2H5006.4H3144.9L3150.9,2108.6z"
        />
      </g>
    </g>
  </svg>
);
export default ZaloFullSize;
