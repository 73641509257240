import { all, call, delay, put } from 'redux-saga/effects';
import { HomeTypes } from '@adapters/redux/reducer/home';
import API from '@services/api';
import LocalStorageService from '@services/localStorage';
import { FAKE_DATA, HOME_DATA } from '@services/constant';

export function* startup() {
  const localData = yield call([LocalStorageService, 'get'], HOME_DATA);
  if (localData)
    yield put({
      type: HomeTypes.GET_PAGES_DATA_SUCCESS,
      payload: { ...localData, appReady: true },
    });
  yield call(getAllPages);
  yield put({
    type: HomeTypes.GET_PAGES_DATA_SUCCESS,
    payload: { appReady: true },
  });
}

export function* getAllPages() {
  try {
    const responses = yield all([
      call(getData, 'homepage', 'homePage'),
      call(getData, 'owner', 'ownerInfo'),
      call(getData, 'contact-us', 'contactUs'),
      call(getData, 'page/rooms', 'rooms'),
      call(getData, 'offers', 'offers'),
      call(getData, 'destinations', 'destinations'),
      call(getData, 'meeting-event', 'events'),
      call(getData, 'comments', 'comments'),
      call(getData, 'spas', 'spas'),
      call(getData, 'dinnings', 'dinnings'),
      call(getData, 'experiences', 'experiences'),
      call(getData, 'galleries', 'galleries'),
    ]);
    const flatResponses = responses.flat();
    const data = flatResponses.reduce(
      (obj, item) => Object.assign(obj, { [item.field]: item.response }),
      {}
    );
    yield call([LocalStorageService, 'set'], HOME_DATA, data);
    yield put({
      type: HomeTypes.GET_PAGES_DATA_SUCCESS,
      payload: { ...data, appReady: true },
    });
  } catch (error) {
    yield put({
      type: HomeTypes.GET_PAGES_DATA_FAILURE,
      error: error.message,
    });
  }
}

export function* getData(url, field) {
  try {
    const response = yield call(API.fetchPageData, url);
    switch (field) {
      case 'rooms':
        return { field, response: response[field] };
      case 'homePage':
        return [
          { field: 'homePage', response },
          { field: 'intro_section', response: response.intro_section },
          { field: 'video_banner', response: response.video_banner },
          { field: 'section1', response: response.section1 },
          { field: 'section2', response: response.section2 },
          { field: 'section3', response: response.section3 },
          { field: 'experience_intro', response: response.experience },
        ];
      case 'contactUs':
        return [
          { field: 'contactUs', response },
          { field: 'subscribe_section', response: response.subscribe_section },
        ];
      case 'aboutUs':
        return [
          { field: 'aboutUs', response },
          { field: 'section1', response: response.section1 },
          { field: 'section2', response: response.section2 },
          { field: 'section3', response: response.section3 },
        ];
      default:
        return { field, response };
    }
  } catch (error) {
    console.log(`Get page ${url} error: ${error.message}`);
  }
}

export function* getPageDetail({ params }) {
  const { url, page } = params;
  try {
    const response = yield call(API.fetchPageData, url);
    yield put({
      type: HomeTypes.GET_PAGE_DETAIL_SUCCESS,
      payload: { [page]: response },
    });
  } catch (error) {
    console.log(`Get page ${url} error: ${error.message}`);
    yield put({
      type: HomeTypes.GET_PAGE_DETAIL_FAILURE,
      error: error.message,
    });
  }
}

export function* subscribeEmail({ params }) {
  try {
    yield call(API.postRequest, 'subscribe', params);
    yield put({
      type: HomeTypes.SUBSCRIBE_EMAIL_SUCCESS,
      payload: {
        type: 'success',
        title: 'Subscribe successfully',
        text: 'We will send the latest promotion information via the email you just registered',
      },
    });
    yield delay(1000);
    yield put({
      type: HomeTypes.SUBSCRIBE_EMAIL_SUCCESS,
      payload: null,
    });
  } catch (error) {
    yield put({
      type: HomeTypes.SUBSCRIBE_EMAIL_FAILURE,
      error: {
        type: 'error',
        title: 'Subscribe error',
        text: error.message,
      },
    });
    yield delay(1000);
    yield put({
      type: HomeTypes.SUBSCRIBE_EMAIL_SUCCESS,
      payload: null,
    });
  }
}

export function* getInTouch({ params }) {
  try {
    yield call(API.postRequest, 'get-in-touch', params);
    yield put({
      type: HomeTypes.GET_IN_TOUCH_SUCCESS,
      payload: {
        type: 'success',
        title: 'Email sent successfully',
        text: 'We have received your email and will get back to you soon.',
      },
    });
    yield delay(1000);
    yield put({
      type: HomeTypes.GET_IN_TOUCH_SUCCESS,
      payload: null,
    });
  } catch (error) {
    yield put({
      type: HomeTypes.GET_IN_TOUCH_FAILURE,
      error: {
        type: 'error',
        title: 'Get in touch error',
        text: error.message,
      },
    });
    yield delay(1000);
    yield put({
      type: HomeTypes.GET_IN_TOUCH_SUCCESS,
      payload: null,
    });
  }
}
