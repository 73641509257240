import { Link } from 'react-router-dom';
import React, { useState } from 'react';
import FsLightbox from 'fslightbox-react';
import { connect } from 'react-redux';
import { convertHexToRGBA, fullBackendPath, kebabCase } from '@core/helpers';
import ErrorBoundary from '@components/ErrorBoundary';
import { useTranslation } from 'react-i18next';
import OfferBG from '../../../assets/img/bg/offer_bg.jpeg';

const Offers = (props) => {
  const { t, i18n } = useTranslation();
  const { offers, intro_section, video_banner } = props;
  const { link: videoLink, title: videoTitle, video_background } = video_banner;

  const { intro_image1 } = intro_section;

  const [yShow, setyShow] = useState();
  const [toggler, setToggler] = useState(false);

  const [activeImage, setActiveImage] = useState(1);

  const images = offers.map((offer) =>
    fullBackendPath(offer.offer_background.path)
  );

  return (
    <>
      <main>
        <section
          className="breadcrumb-area breadcrumb-area-detail d-flex align-items-center background-header"
          style={{
            background: `url("${OfferBG}")`,
          }}
        >
          <div className="container">
            <div className="row align-items-center">
              <div className="col-xl-12 col-lg-12">
                <div className="breadcrumb-wrap text-center">
                  <div className="breadcrumb-title">
                    <h2>{t('Offers')}</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          id="services"
          className="services-area pt-90 pb-90"
          style={{ background: convertHexToRGBA('#ffffff', 0.8) }}
        >
          <div className="container">
            <div className="row justify-content-center">
              {offers.map((offer, index) => {
                const { id, name, name_vi, offer_background } = offer;

                return (
                  <div key={id} className="col-xl-4 col-md-6 mb-4 px-2px">
                    <Link
                      to={`/offer-detail/${kebabCase(name)}`}
                      state={{ id }}
                      className="gallery-link popup-image"
                    >
                      <div className="single-services position-relative">
                        <div className="services-thumb">
                          <img
                            src={fullBackendPath(offer_background?.path)}
                            alt="img"
                          />
                        </div>
                        <div className="services-thumb-hover">
                          <div className="border-1">
                            <div className="border-2 text-center">
                              {i18n.language === 'en'
                                ? name || name_vi
                                : name_vi || name}
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                );
              })}
            </div>
          </div>
        </section>
      </main>

      <FsLightbox toggler={toggler} sources={images} slide={activeImage} />

      {yShow && (
        <>
          <div className="mfp-bg mfp-ready"></div>
          <div
            className="mfp-wrap mfp-close-btn-in mfp-auto-cursor mfp-ready"
            tabIndex="-1"
            style={{ overflow: 'hidden' }}
          >
            <div className="mfp-container mfp-s-ready mfp-iframe-holder">
              <div className="mfp-content">
                <div className="mfp-iframe-scaler">
                  <button
                    title="Close (Esc)"
                    type="button"
                    className="mfp-close"
                    onClick={() => setyShow(false)}
                  >
                    ×
                  </button>
                  <iframe
                    title="iframe"
                    className="mfp-iframe"
                    src={videoLink}
                    frameBorder="0"
                    allowFullScreen=""
                  ></iframe>
                </div>
              </div>
              <div className="mfp-preloader">Loading...</div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  intro_section: state.home.intro_section,
  offers: state.home.offers,
  video_banner: state.home.video_banner,
});

const mapDispatchToProps = () => ({});

const withErrorBoundary = (props) => (
  <ErrorBoundary>
    <Offers {...props} />
  </ErrorBoundary>
);

export default connect(mapStateToProps, mapDispatchToProps)(withErrorBoundary);
