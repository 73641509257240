import { Link } from 'react-router-dom';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Swal from 'sweetalert2';
import { HomeActions } from '@adapters/redux/reducer/home';
import ErrorBoundary from '@components/ErrorBoundary';
import { convertHexToRGBA, fullBackendPath } from '@core/helpers';
import { useTranslation } from 'react-i18next';
import ContactBG from '../../assets/img/bg/contact_bg.jpg';

const Contact = (props) => {
  const { t, i18n } = useTranslation();
  const {
    intro_section,
    sendingGetInTouch,
    getInTouch,
    getInTouchNoti,
    sendingSubscribe,
    subscribeEmail,
    ownerInfo,
  } = props;

  const {
    phone1,
    phone2,
    email1,
    email2,
    address,
    address_vi,
    working_hour,
    working_hour_vi,
  } = ownerInfo;
  const { intro_image1 } = intro_section;

  useEffect(() => {
    if (getInTouchNoti)
      Swal.fire({
        title: getInTouchNoti?.title,
        text: getInTouchNoti?.text,
        icon: getInTouchNoti?.type,
        confirmButtonText: 'OK',
        confirmButtonColor: '#2e1913',
      });
  }, [getInTouchNoti]);

  const onClickSendGetInTouch = () => {
    const name = document.getElementById('name').value;
    const email = document.getElementById('email').value;
    const phone = document.getElementById('phone').value;
    const subject = document.getElementById('subject').value;
    const content = document.getElementById('content').value;

    if (name && email && phone && subject && content) {
      getInTouch({ name, email, phone, subject, content });
    }
  };

  const onClickSubscribe = () => {
    const email = document.getElementById('email3').value;
    if (email) {
      subscribeEmail(email);
    }
  };

  return (
    <>
      <main>
        <section
          className="breadcrumb-area breadcrumb-area-detail d-flex align-items-center background-header"
          style={{
            background: `url("${ContactBG}")`,
          }}
        >
          <div className="container">
            <div className="row align-items-center">
              <div className="col-xl-12 col-lg-12">
                <div className="breadcrumb-wrap text-center">
                  <div className="breadcrumb-title">
                    <h2>{t('Contact Us')}</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          id="contact"
          className="contact-area after-none contact-bg pt-120 pb-120 p-relative fix"
          style={{ background: convertHexToRGBA('#ffffff', 0.8) }}
        >
          <div className="container">
            <div className="row justify-content-center align-items-center">
              <div className="col-lg-4 order-1">
                <div className="contact-info">
                  <div
                    className="single-cta pb-30 mb-30 wow fadeInUp animated"
                    data-animation="fadeInDown animated"
                    data-delay=".2s"
                  >
                    <div className="f-cta-icon">
                      <i className="far fa-map"></i>
                    </div>
                    <h5>{t('Office address')}</h5>
                    <p>
                      {i18n.language === 'en'
                        ? address || address_vi
                        : address_vi || address}
                    </p>
                  </div>
                  <div
                    className="single-cta wow fadeInUp animated"
                    data-animation="fadeInDown animated"
                    data-delay=".2s"
                  >
                    <div className="f-cta-icon">
                      <i className="far fa-envelope-open"></i>
                    </div>
                    <h5>{t('Message us')}</h5>
                    <p>
                      <Link to="#">{email1}</Link>
                      <br />
                      <Link to="#">{email2}</Link>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-8 order-2">
                <div
                  className="contact-bg02"
                  style={{ background: convertHexToRGBA('#f7f5f1', 0.6) }}
                >
                  <div
                    className="section-title center-align mb-40 text-center wow fadeInDown animated"
                    data-animation="fadeInDown"
                    data-delay=".4s"
                  >
                    <h2>{t('Enquiries')}</h2>
                  </div>
                  <div className="contact-form mt-30">
                    <div className="row">
                      <div className="col-lg-6 col-md-6">
                        <div className="contact-field p-relative c-name mb-20">
                          <input
                            type="text"
                            id="name"
                            name="name"
                            placeholder={t('Your name')}
                            required
                          />
                        </div>
                      </div>

                      <div className="col-lg-6 col-md-6">
                        <div className="contact-field p-relative c-subject mb-20">
                          <input
                            type="text"
                            id="email"
                            name="email"
                            placeholder={t('Email')}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <div className="contact-field p-relative c-subject mb-20">
                          <input
                            type="text"
                            id="phone"
                            name="phone"
                            placeholder={t('Phone no.')}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <div className="contact-field p-relative c-subject mb-20">
                          <input
                            type="text"
                            id="subject"
                            name="subject"
                            placeholder={t('Subject')}
                          />
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="contact-field p-relative c-message mb-30">
                          <textarea
                            name="content"
                            id="content"
                            cols="30"
                            rows="10"
                            placeholder={t('Write comments')}
                          ></textarea>
                        </div>
                        <div className="slider-btn">
                          <button
                            className="btn ss-btn"
                            data-animation="fadeInRight"
                            data-delay=".8s"
                            disabled={sendingGetInTouch}
                            onClick={onClickSendGetInTouch}
                          >
                            {sendingGetInTouch ? (
                              <i className="fa fa-spinner fa-spin" />
                            ) : (
                              t('Submit Now')
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

const mapStateToProps = (state) => ({
  intro_section: state.home.intro_section,
  ownerInfo: state.home.ownerInfo,
  sendingGetInTouch: state.home.sendingGetInTouch,
  getInTouchNoti: state.home.getInTouchNoti,
  sendingSubscribe: state.home.sendingSubscribe,
});

const mapDispatchToProps = (dispatch) => ({
  getInTouch: (params) => dispatch(HomeActions.getInTouch(params)),
  subscribeEmail: (email) => dispatch(HomeActions.subscribeEmail({ email })),
});

const withErrorBoundary = (props) => (
  <ErrorBoundary>
    <Contact {...props} />
  </ErrorBoundary>
);

export default connect(mapStateToProps, mapDispatchToProps)(withErrorBoundary);
