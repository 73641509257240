import React, { useEffect, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import BGTest from '@assets/img/bg/testimonial-bg.png';
import Slider from 'react-slick';
import FsLightbox from 'fslightbox-react';
import Aicon from '@assets/img/testimonial/qt-icon.png';
import Aicon1 from '@assets/img/testimonial/review-icon.png';
import Booking from '@assets/img/bg/booking-img.jpg';
import { connect } from 'react-redux';
import ErrorBoundary from '@components/ErrorBoundary';
import {
  bookNow,
  convertHexToRGBA,
  fullBackendPath,
  getRoomFeatureClass,
  getRoomFeatureName,
  kebabCase,
} from '@core/helpers';
import { ROOM_FEATURES } from '@core/services/constant';
import { useTranslation } from 'react-i18next';
import DatePicker from 'react-datepicker';
import moment from 'moment';

const Home = (props) => {
  const { t, i18n } = useTranslation();
  const { state } = useLocation();

  const {
    section1,
    section2,
    section3,
    rooms,
    intro_section,
    experience_intro,
    aboutRef,
  } = props;

  useEffect(() => {
    if (state?.goToAbout)
      aboutRef?.current?.scrollIntoView({
        behavior: 'smooth',
      });
  }, state);

  const {
    describe,
    describe_vi,
    intro_image1,
    intro_image2,
    intro_image3,
    link,
    title,
    title_vi,
  } = intro_section;

  const {
    describe: section1Describe,
    describe_vi: section1DescribeVie,
    sec1_image1,
    sec1_image2,
  } = section1;

  const {
    describe: section2Describe,
    describe_vi: section2DescribeVie,
    sec2_image,
  } = section2;

  const {
    describe: section3Describe,
    describe_vi: section3DescribeVie,
    sec3_image,
  } = section3;

  const {
    describe: section4Describe,
    describe_vi: section4DescribeVie,
    sec3_image: section4Image,
  } = experience_intro;

  const [hShow, sethShow] = useState();
  const [checkInDate, setCheckInDate] = useState(null);
  const [checkOutDate, setCheckOutDate] = useState(null);

  const home = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 1000,
    autoplaySpeed: 3000,
    autoplay: true,
    slidesToShow: 1,
  };

  const onClickCheckAvailability = () => {
    const checkin = moment(checkInDate || new Date()).format('DD/MM/YYYY');
    const checkout = moment(checkOutDate || new Date()).format('DD/MM/YYYY');
    const adults = document.getElementById('adults').value;
    const children = document.getElementById('children').value;
    const bookLink = `https://book-directonline.com/properties/anhillboutiquedirect?locale=en&items[0][adults]=${adults}&items[0][children]=${children}&currency=VND&checkInDate=${checkin}&checkOutDate=${checkout}`;
    window.open(bookLink, '_blank').focus();
  };

  return (
    <>
      <main>
        <section id="home" className="slider-area fix p-relative">
          <Slider className="slider-active" {...home}>
            <div className="col-xl-2">
              <div
                className="single-slider slider-bg d-flex align-items-center"
                style={{
                  backgroundImage: `url("${fullBackendPath(
                    intro_image1?.path
                  )}")`,
                }}
              >
                <div className="container">
                  <div className="row justify-content-center align-items-center">
                    <div className="col-lg-7 col-md-7">
                      <div className="slider-content s-slider-content mt-80 text-center">
                        <h2
                          data-animation="fadeInUp"
                          data-delay=".4s"
                          style={{ animationDelay: '0.4s' }}
                        >
                          {i18n.language === 'en'
                            ? title || title_vi
                            : title_vi || title}
                        </h2>
                        <p data-animation="fadeInUp" data-delay=".6s">
                          {i18n.language === 'en'
                            ? describe || describe_vi
                            : describe_vi || describe}
                        </p>
                        <div className="slider-btn mt-30 mb-105">
                          <Link
                            to="#"
                            onClick={() => sethShow(true)}
                            className="video-i popup-video"
                            data-animation="fadeInUp"
                            data-delay=".8s"
                            style={{ animation_delay: ' 0.8s' }}
                            tabIndex="0"
                          >
                            <i className="fas fa-play"></i>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-2">
              <div
                className="single-slider slider-bg d-flex align-items-center"
                style={{
                  backgroundImage: `url("${fullBackendPath(
                    intro_image2?.path
                  )}")`,
                  backgroundSize: 'cover',
                }}
              >
                <div className="container">
                  <div className="row justify-content-center align-items-center">
                    <div className="col-lg-7 col-md-7">
                      <div className="slider-content s-slider-content mt-80 text-center">
                        <h2
                          data-animation="fadeInUp"
                          data-delay=".4s"
                          style={{ animationDelay: '0.4s' }}
                        >
                          {i18n.language === 'en'
                            ? title || title_vi
                            : title_vi || title}
                        </h2>
                        <p data-animation="fadeInUp" data-delay=".6s">
                          {i18n.language === 'en'
                            ? describe || describe_vi
                            : describe_vi || describe}
                        </p>
                        <div className="slider-btn mt-30 mb-105">
                          <Link
                            to="#"
                            onClick={() => sethShow(true)}
                            className="video-i popup-video"
                            data-animation="fadeInUp"
                            data-delay=".8s"
                            style={{ animation_delay: ' 0.8s' }}
                            tabIndex="0"
                          >
                            <i className="fas fa-play"></i>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-2">
              <div
                className="single-slider slider-bg d-flex align-items-center"
                style={{
                  backgroundImage: `url("${fullBackendPath(
                    intro_image3?.path
                  )}")`,
                  backgroundSize: 'cover',
                }}
              >
                <div className="container">
                  <div className="row justify-content-center align-items-center">
                    <div className="col-lg-7 col-md-7">
                      <div className="slider-content s-slider-content mt-80 text-center">
                        <h2
                          data-animation="fadeInUp"
                          data-delay=".4s"
                          style={{ animationDelay: '0.4s' }}
                        >
                          {i18n.language === 'en'
                            ? title || title_vi
                            : title_vi || title}
                        </h2>
                        <p data-animation="fadeInUp" data-delay=".6s">
                          {i18n.language === 'en'
                            ? describe || describe_vi
                            : describe_vi || describe}
                        </p>
                        <div className="slider-btn mt-30 mb-105">
                          <Link
                            to="#"
                            onClick={() => sethShow(true)}
                            className="video-i popup-video"
                            data-animation="fadeInUp"
                            data-delay=".8s"
                            style={{ animation_delay: ' 0.8s' }}
                            tabIndex="0"
                          >
                            <i className="fas fa-play"></i>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Slider>
        </section>

        <div id="booking" className="booking-area p-relative">
          <div className="container">
            <div className="row align-items-center contact-form">
              <div className="col-lg-12">
                <ul>
                  <li className="hidden-on-mobile">
                    <div className="contact-field p-relative c-name">
                      <label>
                        <i className="fal fa-badge-check"></i>{' '}
                        {t('Check In Date')}
                      </label>
                      <DatePicker
                        dateFormat="dd/MM/yyyy"
                        showIcon
                        selected={checkInDate}
                        onChange={(date) => setCheckInDate(date)}
                        icon="fal fa-calendar"
                      />
                    </div>
                  </li>
                  <li className="hidden-on-mobile">
                    <div className="contact-field p-relative c-name">
                      <label>
                        <i className="fal fa-times-octagon"></i>{' '}
                        {t('Check Out Date')}
                      </label>
                      <DatePicker
                        dateFormat="dd/MM/yyyy"
                        showIcon
                        selected={checkOutDate}
                        onChange={(date) => setCheckOutDate(date)}
                        icon="fal fa-calendar"
                      />
                    </div>
                  </li>
                  <li className="hidden-on-mobile">
                    <div className="contact-field p-relative c-name">
                      <label>
                        <i className="fal fa-users"></i> {t('Adults')}
                      </label>
                      <select name="adults" id="adults">
                        <option value="">{t('Adults')}</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                      </select>
                    </div>
                  </li>
                  <li className="hidden-on-mobile">
                    <div className="contact-field p-relative c-name">
                      <label>
                        <i className="fal fa-baby"></i> {t('Children')}
                      </label>
                      <select name="children" id="children">
                        <option value="">{t('Children')}</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                      </select>
                    </div>
                  </li>
                  <li className="button-check-availability">
                    <div className="slider-btn">
                      <label>&nbsp;</label>
                      <button
                        className="btn ss-btn"
                        data-animation="fadeInRight"
                        data-delay=".8s"
                        onClick={onClickCheckAvailability}
                        style={{ zIndex: 'unset' }}
                      >
                        {t('Check Availability')}
                      </button>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <section
          ref={aboutRef}
          className="about-area about-p pt-90 pb-40 p-relative fix m-pt-0"
          style={{ background: convertHexToRGBA('#ffffff', 0.8) }}
        >
          <div className="container">
            <div className="row justify-content-center align-items-center">
              <div className="col-lg-6 col-md-12 col-sm-12 mobile-order-0">
                <div
                  className="s-about-img p-relative wow fadeInLeft animated"
                  data-animation="fadeInLeft"
                  data-delay=".4s"
                >
                  <div className="position-relative image-container-homepage-section">
                    <img
                      src={fullBackendPath(sec1_image1?.path)}
                      alt="img"
                      className="image-homepage-section"
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12mobile-order-1">
                <div
                  className="about-content s-about-content wow fadeInRight animated pl-30"
                  data-animation="fadeInRight"
                  data-delay=".4s"
                >
                  <div
                    className="ck-content"
                    dangerouslySetInnerHTML={{
                      __html:
                        i18n.language === 'en'
                          ? section1Describe || section1DescribeVie
                          : section1DescribeVie || section1Describe,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        {rooms.map((room, index) => {
          const {
            id,
            room_background,
            price,
            name,
            name_vi,
            describe,
            describe_vi,
            room_feature,
          } = room;

          if (index === 0)
            return (
              <section
                key={`room-${index}`}
                className="about-area about-p pt-50 pb-40 p-relative fix m-pt-0"
                style={{ background: convertHexToRGBA('#f7f5f1', 0.8) }}
              >
                <div className="container">
                  <div className="row justify-content-center align-items-center">
                    <div className="col-lg-6 col-md-12 col-sm-12 mobile-order-1">
                      <div
                        className="about-content s-about-content wow fadeInRight animated pl-30"
                        data-animation="fadeInRight"
                        data-delay=".4s"
                      >
                        <h2 style={{ color: '#644222' }}>
                          {t('Rooms & Suites')}
                        </h2>
                        <div
                          className="ck-content"
                          dangerouslySetInnerHTML={{
                            __html:
                              i18n.language === 'en'
                                ? describe || describe_vi
                                : describe_vi || describe,
                          }}
                        />
                        <div className="about-content3 mt-30">
                          <div className="row justify-content-center align-items-center">
                            <div className="col-md-6">
                              <div className="slider-btn text-center mb-30">
                                <Link
                                  to={"/rooms"}
                                  className="btn ss-btn smoth-scroll"
                                >
                                  {t('Discover More')}
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12 mobile-order-0">
                      <div
                        className="s-about-img p-relative wow fadeInLeft animated"
                        data-animation="fadeInLeft"
                        data-delay=".4s"
                      >
                        <div className="position-relative image-container-homepage-section">
                          <img
                            src={fullBackendPath(room_background?.path)}
                            alt="Experiences"
                            className="image-homepage-section"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            );
        })}

        <section
          className="about-area about-p pt-50 pb-40 p-relative fix m-pt-0"
          style={{ background: convertHexToRGBA('#ffffff', 0.8) }}
        >
          <div className="container">
            <div className="row justify-content-center align-items-center">
              <div className="col-lg-6 col-md-12 col-sm-12 mobile-order-0">
                <div
                  className="s-about-img p-relative wow fadeInLeft animated"
                  data-animation="fadeInLeft"
                  data-delay=".4s"
                >
                  <div className="position-relative image-container-homepage-section">
                    <img
                      src={fullBackendPath(sec2_image?.path)}
                      alt={t('Dining')}
                      className="image-homepage-section"
                    />
                  </div>

                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12mobile-order-1">
              <div
                  className="about-content s-about-content wow fadeInRight animated pl-30"
                  data-animation="fadeInRight"
                  data-delay=".4s"
                >
                  <div
                    className="ck-content"
                    dangerouslySetInnerHTML={{
                      __html:
                        i18n.language === 'en'
                          ? section2Describe || section2DescribeVie
                          : section2DescribeVie || section2Describe,
                    }}
                  />
                  <div className="about-content3 mt-30">
                    <div className="row justify-content-center align-items-center">
                      <div className="col-md-6">
                        <div className="slider-btn text-center mb-30">
                          <Link
                            to="/dining"
                            className="btn ss-btn smoth-scroll"
                          >
                            {t('Discover More')}
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section
          className="about-area about-p pt-50 pb-40 p-relative fix m-pt-0"
          style={{ background: convertHexToRGBA('#f7f5f1', 0.8) }}
        >
          <div className="container">
            <div className="row justify-content-center align-items-center">
              <div className="col-lg-6 col-md-12 col-sm-12 mobile-order-1">
              <div
                  className="about-content s-about-content wow fadeInRight animated pl-30"
                  data-animation="fadeInRight"
                  data-delay=".4s"
                >
                  <div
                    className="ck-content"
                    dangerouslySetInnerHTML={{
                      __html:
                        i18n.language === 'en'
                          ? section3Describe || section3DescribeVie
                          : section3DescribeVie || section3Describe,
                    }}
                  />
                  <div className="about-content3 mt-30">
                    <div className="row justify-content-center align-items-center">
                      <div className="col-md-6">
                        <div className="slider-btn text-center mb-30">
                          <Link
                            to="/spa-wellness"
                            className="btn ss-btn smoth-scroll"
                          >
                            {t('Discover More')}
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12 mobile-order-0">
                <div
                  className="s-about-img p-relative wow fadeInLeft animated"
                  data-animation="fadeInLeft"
                  data-delay=".4s"
                >
                  <div className="position-relative image-container-homepage-section">
                    <img
                      src={fullBackendPath(sec3_image?.path)}
                      alt={t('Spa & Wellness')}
                      className="image-homepage-section"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section
          className="about-area about-p pt-50 pb-90 p-relative fix"
          style={{ background: convertHexToRGBA('#ffffff', 0.8) }}
        >
          <div className="container">
            <div className="row justify-content-center align-items-center">
              <div className="col-lg-6 col-md-12 col-sm-12 mobile-order-0">
                <div
                  className="s-about-img p-relative wow fadeInLeft animated"
                  data-animation="fadeInLeft"
                  data-delay=".4s"
                >
                  <div className="position-relative image-container-homepage-section">
                    <img
                      src={fullBackendPath(section4Image?.path)}
                      alt="Experiences"
                      className="image-homepage-section"
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12 mobile-order-1">
                <div
                    className="about-content s-about-content wow fadeInRight animated pl-30"
                    data-animation="fadeInRight"
                    data-delay=".4s"
                >
                  <div
                    className="ck-content"
                    dangerouslySetInnerHTML={{
                      __html:
                        i18n.language === 'en'
                          ? section4Describe || section4DescribeVie
                          : section4DescribeVie || section4Describe,
                    }}
                  />
                  <div className="about-content3 mt-30">
                    <div className="row justify-content-center align-items-center">
                      <div className="col-md-6">
                        <div className="slider-btn text-center mb-30">
                          <Link
                            to="/experiences"
                            className="btn ss-btn smoth-scroll"
                          >
                            {t('Discover More')}
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>

      {hShow && (
        <>
          <div className="mfp-bg mfp-ready"></div>
          <div
            className="mfp-wrap mfp-close-btn-in mfp-auto-cursor mfp-ready"
            tabIndex="-1"
            style={{ overflow: 'hidden' }}
          >
            <div className="mfp-container mfp-s-ready mfp-iframe-holder">
              <div className="mfp-content">
                <div className="mfp-iframe-scaler">
                  <button
                    title="Close (Esc)"
                    type="button"
                    className="mfp-close"
                    onClick={() => sethShow(false)}
                  >
                    ×
                  </button>
                  <iframe
                    title="loading-iframe"
                    className="mfp-iframe"
                    src={link}
                    frameBorder="0"
                    allowFullScreen=""
                  ></iframe>
                </div>
              </div>
              <div className="mfp-preloader">Loading...</div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  section1: state.home.section1,
  section2: state.home.section2,
  section3: state.home.section3,
  experience_intro: state.home.experience_intro,
  rooms: state.home.rooms,
  intro_section: state.home.intro_section,
});

const mapDispatchToProps = () => ({});

const withErrorBoundary = (props) => (
  <ErrorBoundary>
    <Home {...props} />
  </ErrorBoundary>
);

export default connect(mapStateToProps, mapDispatchToProps)(withErrorBoundary);
