import '@assets/css/bootstrap.min.css';
import '@assets/css/animate.min.css';
import '@assets/css/magnific-popup.css';
import '@assets/fontawesome/css/all.min.css';
import '@assets/css/dripicons.css';
import '@assets/css/slick.css';
import '@assets/css/meanmenu.css';
import '@assets/css/default.css';
import '@assets/css/style.css';
import '@assets/css/responsive.css';
import 'react-datepicker/dist/react-datepicker.css';

import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import Routing from './routes';
import { connect } from 'react-redux';
import GlobalLoader from '@components/Loader/GlobalLoader';

const App = (props) => {
  return (
    <BrowserRouter>
      {props.appReady ? <Routing /> : <GlobalLoader />}
    </BrowserRouter>
  );
};

const mapStateToProps = (state) => ({
  appReady: state.home.appReady,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(App);
