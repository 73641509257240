// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import { all, takeLatest } from 'redux-saga/effects';
import { HomeTypes } from '../reducer/home';
import { getInTouch, getPageDetail, startup, subscribeEmail } from './home';

const homeSagas = [
  takeLatest(HomeTypes.GET_PAGES_DATA, startup),
  takeLatest(HomeTypes.SUBSCRIBE_EMAIL, subscribeEmail),
  takeLatest(HomeTypes.GET_IN_TOUCH, getInTouch),
  takeLatest(HomeTypes.GET_PAGE_DETAIL, getPageDetail),
];

export default function* root() {
  yield all([...homeSagas]);
}
