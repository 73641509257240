import { Link } from 'react-router-dom';
import React from 'react';
import { connect } from 'react-redux';
import { HomeActions } from '@adapters/redux/reducer/home';
import ErrorBoundary from '@components/ErrorBoundary';
import { convertHexToRGBA, fullBackendPath } from '@core/helpers';
import { useTranslation } from 'react-i18next';
import BGTestimonial from '@assets/img/bg/testimonial-bg.png';

const About = (props) => {
  const { t, i18n } = useTranslation();
  const {
    intro_section,
    section1,
    section2,
    section3,
    subscribeEmail,
    sendingSubscribe,
  } = props;
  const { intro_image1 } = intro_section;
  const {
    describe: section1Describe,
    describe_vi: section1DescribeVie,
    sec1_image1,
    sec1_image2,
  } = section1;
  const {
    describe: section2Describe,
    describe_vi: section2DescribeVie,
    sec2_image,
    percent1,
    percent2,
    percent3,
    progress_bar1,
    progress_bar2,
    progress_bar3,
    progress_bar1_vi,
    progress_bar2_vi,
    progress_bar3_vi,
  } = section2;
  const {
    describe: section3Describe,
    describe_vi: section3DescribeVie,
    sec3_image,
  } = section3;

  const onClickSubscribe = () => {
    const email = document.getElementById('email3').value;
    if (email) {
      subscribeEmail(email);
    }
  };

  return (
    <>
      <main>
        <section
          className="breadcrumb-area breadcrumb-area-detail d-flex align-items-center background-header"
          style={{
            background: `url("${fullBackendPath(intro_image1?.path)}")`,
          }}
        >
          <div className="container">
            <div className="row align-items-center">
              <div className="col-xl-12 col-lg-12">
                <div className="breadcrumb-wrap text-center">
                  <div className="breadcrumb-title">
                    <h2>{t('About Us')}</h2>
                    {/* <div className="breadcrumb-wrap">
                      <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                          <li className="breadcrumb-item">
                            <Link to="/">{t('Home')}</Link>
                          </li>
                          <li
                            className="breadcrumb-item active"
                            aria-current="page"
                          >
                            {t('About Us')}
                          </li>
                        </ol>
                      </nav>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          className="about-area about-p pt-120 pb-120 p-relative fix"
          style={{ background: convertHexToRGBA('#ffffff', 0.8) }}
        >
          <div className="container">
            <div className="row justify-content-center align-items-center">
              <div className="col-lg-6 col-md-12 col-sm-12">
                <div
                  className="s-about-img p-relative wow fadeInLeft animated"
                  data-animation="fadeInLeft"
                  data-delay=".4s"
                >
                  <img src={fullBackendPath(sec1_image1?.path)} alt="img" />
                  <div className="about-icon">
                    <img src={fullBackendPath(sec1_image2?.path)} alt="img" />
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12">
                <div
                  className="about-content s-about-content wow fadeInRight animated pl-30"
                  data-animation="fadeInRight"
                  data-delay=".4s"
                >
                  <div
                    className="ck-content"
                    dangerouslySetInnerHTML={{
                      __html:
                        i18n.language === 'en'
                          ? section1Describe || section1DescribeVie
                          : section1DescribeVie || section1Describe,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          id="skill"
          className="skill-area p-relative fix"
          style={{ background: convertHexToRGBA('#291d16', 0.9) }}
        >
          <div className="container">
            <div className="row justify-content-center align-items-center">
              <div className="col-lg-6 col-md-12 col-sm-12">
                <div className="skills-content s-about-content">
                  <div
                    className="ck-content"
                    dangerouslySetInnerHTML={{
                      __html:
                        i18n.language === 'en'
                          ? section3Describe || section3DescribeVie
                          : section3DescribeVie || section3Describe,
                    }}
                  />
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12 pr-30">
                <div
                  className="skills-img wow fadeInRight  animated"
                  data-animation="fadeInRight"
                  data-delay=".4s"
                >
                  <img
                    src={fullBackendPath(sec3_image?.path)}
                    alt="img"
                    className="img"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          className="newslater-area p-relative pt-120 pb-120"
          style={{ background: convertHexToRGBA('#f7f5f1', 0.8) }}
        >
          <div className="container">
            <div className="row justify-content-center align-items-center text-center">
              <div className="col-xl-9 col-lg-9">
                <div
                  className="section-title center-align mb-40 text-center wow fadeInDown animated"
                  data-animation="fadeInDown"
                  data-delay=".4s"
                >
                  <h5>{t('Newsletter')}</h5>
                  <h2>{t('Get Best Offers On The Hotel')}</h2>
                  <p>{t('Newsletter Description')}</p>
                </div>
                <div id="contact-form4" className="contact-form newslater">
                  <div className="form-group contact-form newslater">
                    <input
                      className="form-control"
                      id="email3"
                      name="email"
                      type="email"
                      placeholder={t('Your Email Address')}
                    />
                    <button
                      type="submit"
                      className="btn btn-custom"
                      id="send2"
                      onClick={onClickSubscribe}
                    >
                      {sendingSubscribe ? (
                        <i className="fa fa-spinner fa-spin" />
                      ) : (
                        t('Subscribe Now')
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

const mapStateToProps = (state) => ({
  intro_section: state.home.intro_section,
  section1: state.home.section1,
  section2: state.home.section2,
  section3: state.home.section3,
  sendingSubscribe: state.home.sendingSubscribe,
});

const mapDispatchToProps = (dispatch) => ({
  subscribeEmail: (email) => dispatch(HomeActions.subscribeEmail({ email })),
});

const withErrorBoundary = (props) => (
  <ErrorBoundary>
    <About {...props} />
  </ErrorBoundary>
);

export default connect(mapStateToProps, mapDispatchToProps)(withErrorBoundary);
