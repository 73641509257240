class LocalStorage {
  get = async (key) => {
    const data = await localStorage.getItem(key);
    try {
      return JSON.parse(data);
    } catch (error) {
      return data;
    }
  };

  set = async (key, value) => {
    localStorage.setItem(
      key,
      typeof value === 'string' ? value : JSON.stringify(value)
    );
  };
}

const LocalStorageService = new LocalStorage();

export default LocalStorageService;
