const HOME_DATA = 'HOME_DATA';

const ROOM_FEATURES = {
  1: 'TV',
  2: 'Free Wifi',
  3: 'Air Condition',
  4: 'Heater',
  5: 'Phone',
  6: 'Laundry',
};

const BED_TYPES = {
  1: 'Single Bed',
  2: 'Double Bed',
  3: 'Queen Size Bed',
  4: 'King Size Bed',
  5: 'Super King Size Bed',
};

const FAKE_DATA = {
  homePage: {
    id: 2,
    name: 'home',
    section1: {
      id: 1,
      describe:
        "<h2><strong>Most Safe &amp; Rated Resort In Hue.</strong></h2><p>&nbsp;</p><h2>What is Lorem Ipsum?</h2><p><strong>Lorem Ipsum</strong> is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p><h2>Why do we use it?</h2><p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).</p>",
      sec1_image1: {
        id: 1,
        category: 'about',
        name: null,
        path: 'image1.jpg',
      },
      sec1_image2: {
        id: 2,
        category: 'about',
        name: null,
        path: 'image2.jpg',
      },
    },
    section2: null,
    section3: {
      id: 1,
      describe:
        '<h2><strong>Pearl Of The Adriatic.</strong></h2><p>&nbsp;</p><h3><strong>The standard Lorem Ipsum passage, used since the 1500s</strong></h3><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p><h3><strong>Section 1.10.32 of de Finibus Bonorum et Malorum, written by Cicero in 45 BC</strong></h3><p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?</p>',
      link: 'https://www.example.com',
      sec3_image: {
        id: 4,
        category: 'about',
        name: null,
        path: 'sec3-image.jpg',
      },
    },
    subscribe_section: null,
    video_banner: {
      id: 1,
      title: 'Take A Tour Of Luxuri',
      link: 'https://www.youtube.com/watch?v=EKDKB-ZbxPY&ab_channel=Reply1988',
      video_background: {
        id: 5,
        category: 'video',
        name: null,
        path: 'video-background.jpg',
      },
    },
    rooms: [
      {
        id: 1,
        name: 'Superior Double Room',
        price: 400,
        short_describe:
          'Aenean vehicula ligula eu rhoncus porttitor. Duis vel lacinia quam. Nunc rutrum porta ex, in imperdiet tortor feugiat at',
        RoomFeatures: [
          {
            id: 1,
            describe: 'TV',
            feature_icon: {
              id: 6,
              category: 'room feature',
              name: null,
              path: 'tv-icon.jpg',
            },
            RoomFeatureRelation: {
              room_id: 1,
              room_feature_id: 1,
            },
          },
          {
            id: 2,
            describe: 'Free Wifi',
            feature_icon: {
              id: 7,
              category: 'room feature',
              name: null,
              path: 'wifi-icon.jpg',
            },
            RoomFeatureRelation: {
              room_id: 1,
              room_feature_id: 2,
            },
          },
          {
            id: 3,
            describe: 'Laundry',
            feature_icon: {
              id: 8,
              category: 'room feature',
              name: null,
              path: 'laundry-icon.jpg',
            },
            RoomFeatureRelation: {
              room_id: 1,
              room_feature_id: 3,
            },
          },
        ],
        room_background: {
          id: 12,
          category: 'room',
          name: null,
          path: 'double-room-background.jpg',
        },
      },
      {
        id: 2,
        name: 'Delux Room',
        price: 750,
        short_describe:
          'Aenean vehicula ligula eu rhoncus porttitor. Duis vel lacinia quam. Nunc rutrum porta place ullam ipsum. Morbi imperdiet, orci et dapibus.',
        RoomFeatures: [
          {
            id: 2,
            describe: 'Free Wifi',
            feature_icon: {
              id: 7,
              category: 'room feature',
              name: null,
              path: 'wifi-icon.jpg',
            },
            RoomFeatureRelation: {
              room_id: 2,
              room_feature_id: 2,
            },
          },
          {
            id: 3,
            describe: 'Laundry',
            feature_icon: {
              id: 8,
              category: 'room feature',
              name: null,
              path: 'laundry-icon.jpg',
            },
            RoomFeatureRelation: {
              room_id: 2,
              room_feature_id: 3,
            },
          },
        ],
        room_background: {
          id: 16,
          category: 'room',
          name: null,
          path: 'delux-room_background.jpg',
        },
      },
    ],
    services: [
      {
        id: 1,
        name: 'Qulity Room',
        short_describe:
          'Nullam molestie lacus sit amet velit fermentum feugiat. Mauris auctor eget nunc sit amet.',
        service_background: {
          id: 17,
          category: 'service',
          name: null,
          path: 'quality-room-background.jpg',
        },
      },
      {
        id: 2,
        name: 'Privet Beach',
        short_describe: 'Short descript',
        service_background: {
          id: 18,
          category: 'service',
          name: null,
          path: 'privet-beach-background.jpg',
        },
      },
      {
        id: 3,
        name: 'Best Accommodation',
        short_describe: 'Short descript',
        service_background: {
          id: 19,
          category: 'service',
          name: null,
          path: 'accommodation-background.jpg',
        },
      },
    ],
    comments: [
      {
        id: 1,
        first_name: 'A',
        last_name: 'Nguyen',
        star: 5,
        comment:
          'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s',
        avatar: {
          id: 20,
          category: 'client',
          name: null,
          path: 'client1-avatar.jpg',
        },
      },
      {
        id: 2,
        first_name: 'B',
        last_name: 'Huynh',
        star: 5,
        comment:
          'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which dont look even slightly believable.',
        avatar: {
          id: 21,
          category: 'client',
          name: null,
          path: 'client2-avatar.jpg',
        },
      },
      {
        id: 3,
        first_name: 'B',
        last_name: 'Vo',
        star: 5,
        comment:
          'Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source',
        avatar: {
          id: 22,
          category: 'client',
          name: null,
          path: 'client3-avatar.jpg',
        },
      },
    ],
    intro_section: {
      id: 1,
      title: 'Enjoy A Luxuary Experience',
      describe:
        'Donec vitae libero non enim placerat eleifend aliquam erat volutpat. Curabitur diam ex, dapibus purus sapien, cursus sed nisl tristique, commodo gravida lectus non.',
      link: 'https://youtube.com',
      intro_image1: {
        id: 37,
        category: 'intro',
        name: null,
        path: 'intro-background-1.jpg',
      },
      intro_image2: {
        id: 38,
        category: 'intro',
        name: null,
        path: 'intro-background-2.jpg',
      },
    },
  },
  aboutUs: {
    id: 3,
    name: 'about',
    section1: {
      id: 1,
      describe:
        "<h2><strong>Most Safe &amp; Rated Resort In Hue.</strong></h2><p>&nbsp;</p><h2>What is Lorem Ipsum?</h2><p><strong>Lorem Ipsum</strong> is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p><h2>Why do we use it?</h2><p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).</p>",
      sec1_image1: {
        id: 1,
        category: 'about',
        name: null,
        path: 'image1.jpg',
      },
      sec1_image2: {
        id: 2,
        category: 'about',
        name: null,
        path: 'image2.jpg',
      },
    },
    section2: {
      id: 1,
      describe: 'We Offer Wide Selection of Hotel',
      progress_bar1: 'Quality Production',
      percent1: 80,
      progress_bar2: 'Maintenance Services',
      percent2: 90,
      progress_bar3: 'Product Managment',
      percent3: 70,
      sec2_image: {
        id: 3,
        category: 'about',
        name: null,
        path: 'sec2-image2.jpg',
      },
    },
    section3: {
      id: 1,
      describe:
        '<h2><strong>Pearl Of The Adriatic.</strong></h2><p>&nbsp;</p><h3><strong>The standard Lorem Ipsum passage, used since the 1500s</strong></h3><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p><h3><strong>Section 1.10.32 of de Finibus Bonorum et Malorum, written by Cicero in 45 BC</strong></h3><p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?</p>',
      link: 'https://www.example.com',
      sec3_image: {
        id: 4,
        category: 'about',
        name: null,
        path: 'sec3-image.jpg',
      },
    },
    subscribe_section: {
      id: 1,
      describe:
        'With the subscription, enjoy your favourite Hotels without having to think about it',
    },
    video_banner: null,
  },
  ownerInfo: {
    id: 1,
    name: 'An Hill',
    phone1: ' +(84)-935072018',
    phone2: '+84-234 3881234',
    email1: 'info@anhillvietnam.com',
    email2: null,
    address: 'Khai Dinh, An Tây, Hue City,',
    socials: [
      {
        id: 1,
        name: 'facebook',
        link: 'http://facebook.com',
        social_icon: {
          id: 23,
          category: 'social',
          name: null,
          path: 'facebook.jpg',
        },
      },
      {
        id: 2,
        name: 'instagram',
        link: 'http://instagram.com',
        social_icon: {
          id: 24,
          category: 'social',
          name: null,
          path: 'instagram.jpg',
        },
      },
      {
        id: 3,
        name: 'zalo',
        link: 'http://zalo.com',
        social_icon: {
          id: 25,
          category: 'social',
          name: null,
          path: 'zalo.jpg',
        },
      },
    ],
  },
  contactUs: {
    owner: {
      id: 1,
      name: 'An Hill',
      phone1: ' +(84)-935072018',
      phone2: '+84-234 3881234',
      email1: 'info@anhillvietnam.com',
      email2: null,
      address: 'Khai Dinh, An Tây, Hue City,',
      socials: [
        {
          id: 1,
          name: 'facebook',
          link: 'http://facebook.com',
          social_icon: {
            id: 23,
            category: 'social',
            name: null,
            path: 'facebook.jpg',
          },
        },
        {
          id: 2,
          name: 'instagram',
          link: 'http://instagram.com',
          social_icon: {
            id: 24,
            category: 'social',
            name: null,
            path: 'instagram.jpg',
          },
        },
        {
          id: 3,
          name: 'zalo',
          link: 'http://zalo.com',
          social_icon: {
            id: 25,
            category: 'social',
            name: null,
            path: 'zalo.jpg',
          },
        },
      ],
    },
    subscribe_section: {
      id: 1,
      describe:
        'With the subscription, enjoy your favourite Hotels without having to think about it',
    },
  },
  rooms: {
    id: 4,
    name: 'rooms',
    section1: null,
    section2: null,
    section3: {
      id: 1,
      describe:
        '<h2><strong>Pearl Of The Adriatic.</strong></h2><p>&nbsp;</p><h3><strong>The standard Lorem Ipsum passage, used since the 1500s</strong></h3><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p><h3><strong>Section 1.10.32 of de Finibus Bonorum et Malorum, written by Cicero in 45 BC</strong></h3><p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?</p>',
      link: 'https://www.example.com',
      sec3_image: {
        id: 4,
        category: 'about',
        name: null,
        path: 'sec3-image.jpg',
      },
    },
    subscribe_section: null,
    video_banner: {
      id: 1,
      title: 'Take A Tour Of Luxuri',
      link: 'https://www.youtube.com/watch?v=EKDKB-ZbxPY&ab_channel=Reply1988',
      video_background: {
        id: 5,
        category: 'video',
        name: null,
        path: 'video-background.jpg',
      },
    },
    rooms: [
      {
        id: 1,
        name: 'Superior Double Room',
        price: 400,
        short_describe:
          'Aenean vehicula ligula eu rhoncus porttitor. Duis vel lacinia quam. Nunc rutrum porta ex, in imperdiet tortor feugiat at',
        RoomFeatures: [
          {
            id: 1,
            describe: 'TV',
            feature_icon: {
              id: 6,
              category: 'room feature',
              name: null,
              path: 'tv-icon.jpg',
            },
            RoomFeatureRelation: {
              room_id: 1,
              room_feature_id: 1,
            },
          },
          {
            id: 2,
            describe: 'Free Wifi',
            feature_icon: {
              id: 7,
              category: 'room feature',
              name: null,
              path: 'wifi-icon.jpg',
            },
            RoomFeatureRelation: {
              room_id: 1,
              room_feature_id: 2,
            },
          },
          {
            id: 3,
            describe: 'Laundry',
            feature_icon: {
              id: 8,
              category: 'room feature',
              name: null,
              path: 'laundry-icon.jpg',
            },
            RoomFeatureRelation: {
              room_id: 1,
              room_feature_id: 3,
            },
          },
        ],
        room_background: {
          id: 12,
          category: 'room',
          name: null,
          path: 'double-room-background.jpg',
        },
      },
      {
        id: 2,
        name: 'Delux Room',
        price: 750,
        short_describe:
          'Aenean vehicula ligula eu rhoncus porttitor. Duis vel lacinia quam. Nunc rutrum porta place ullam ipsum. Morbi imperdiet, orci et dapibus.',
        RoomFeatures: [
          {
            id: 2,
            describe: 'Free Wifi',
            feature_icon: {
              id: 7,
              category: 'room feature',
              name: null,
              path: 'wifi-icon.jpg',
            },
            RoomFeatureRelation: {
              room_id: 2,
              room_feature_id: 2,
            },
          },
          {
            id: 3,
            describe: 'Laundry',
            feature_icon: {
              id: 8,
              category: 'room feature',
              name: null,
              path: 'laundry-icon.jpg',
            },
            RoomFeatureRelation: {
              room_id: 2,
              room_feature_id: 3,
            },
          },
        ],
        room_background: {
          id: 16,
          category: 'room',
          name: null,
          path: 'delux-room_background.jpg',
        },
      },
    ],
  },
  services: {
    id: 5,
    name: 'services',
    section1: null,
    section2: {
      id: 1,
      describe: 'We Offer Wide Selection of Hotel',
      progress_bar1: 'Quality Production',
      percent1: 80,
      progress_bar2: 'Maintenance Services',
      percent2: 90,
      progress_bar3: 'Product Managment',
      percent3: 70,
      sec2_image: {
        id: 3,
        category: 'about',
        name: null,
        path: 'sec2-image2.jpg',
      },
    },
    section3: {
      id: 1,
      describe:
        '<h2><strong>Pearl Of The Adriatic.</strong></h2><p>&nbsp;</p><h3><strong>The standard Lorem Ipsum passage, used since the 1500s</strong></h3><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p><h3><strong>Section 1.10.32 of de Finibus Bonorum et Malorum, written by Cicero in 45 BC</strong></h3><p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?</p>',
      link: 'https://www.example.com',
      sec3_image: {
        id: 4,
        category: 'about',
        name: null,
        path: 'sec3-image.jpg',
      },
    },
    subscribe_section: null,
    video_banner: null,
    services: [
      {
        id: 1,
        name: 'Qulity Room',
        short_describe:
          'Nullam molestie lacus sit amet velit fermentum feugiat. Mauris auctor eget nunc sit amet.',
        service_background: {
          id: 17,
          category: 'service',
          name: null,
          path: 'quality-room-background.jpg',
        },
      },
      {
        id: 2,
        name: 'Privet Beach',
        short_describe: 'Short descript',
        service_background: {
          id: 18,
          category: 'service',
          name: null,
          path: 'privet-beach-background.jpg',
        },
      },
      {
        id: 3,
        name: 'Best Accommodation',
        short_describe: 'Short descript',
        service_background: {
          id: 19,
          category: 'service',
          name: null,
          path: 'accommodation-background.jpg',
        },
      },
    ],
    comments: [
      {
        id: 1,
        first_name: 'A',
        last_name: 'Nguyen',
        star: 5,
        comment:
          'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s',
        avatar: {
          id: 20,
          category: 'client',
          name: null,
          path: 'client1-avatar.jpg',
        },
      },
      {
        id: 2,
        first_name: 'B',
        last_name: 'Huynh',
        star: 5,
        comment:
          'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which dont look even slightly believable.',
        avatar: {
          id: 21,
          category: 'client',
          name: null,
          path: 'client2-avatar.jpg',
        },
      },
      {
        id: 3,
        first_name: 'B',
        last_name: 'Vo',
        star: 5,
        comment:
          'Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source',
        avatar: {
          id: 22,
          category: 'client',
          name: null,
          path: 'client3-avatar.jpg',
        },
      },
    ],
  },
  offers: {
    offers: [
      {
        id: 1,
        name: 'UNLOCK BANGKOK’S HEART',
        short_describe: 'Short describe',
        offer_background: {
          id: 27,
          category: 'offer',
          name: null,
          path: 'bangkok-background.jpg',
        },
      },
      {
        id: 2,
        name: 'WELLNESS GETAWAY – MIND AND BODY RETREAT',
        short_describe: 'Short describe',
        offer_background: {
          id: 29,
          category: 'offer',
          name: null,
          path: 'season-background.jpg',
        },
      },
      {
        id: 3,
        name: 'SIP AND SAVOUR',
        short_describe: 'Short describe',
        offer_background: {
          id: 31,
          category: 'offer',
          name: null,
          path: 'sip-background.jpg',
        },
      },
    ],
  },
  destinations: {
    destinations: [
      {
        id: 1,
        name: 'Dap xe tren doi Da Phu',
        short_describe: 'Short des',
        des_background: {
          id: 33,
          category: 'destination',
          name: null,
          path: 'da-phu-background.jpg',
        },
      },
      {
        id: 2,
        name: 'Cheo thuyen tren song',
        short_describe: 'Short des',
        des_background: {
          id: 35,
          category: 'destination',
          name: null,
          path: 'cheo-thuyen-background.jpg',
        },
      },
    ],
  },
  events: {
    id: 1,
    describe:
      'Welcome your guests to our urban sanctuary in exciting Bangkok, steps from the city’s popular riverfront and in the heart of its lively creative district. Our unique location showcases Bangkok at its best. Your guests can enjoy sweeping river views from our Four Seasons Grand Ballroom Terrace, or become inspired in The Conservatory with its river-view ballroom and four function rooms. Round out any event by giving your guests the opportunity to mingle on our collection of outdoor terraces.',
    extra_describe: 'TOTAL ACCOMMODATIONS',
    meeting_image: {
      id: 36,
      category: 'meeting-event',
      name: null,
      path: 'meeting-event.jpg',
    },
  },
};

export { HOME_DATA, ROOM_FEATURES, BED_TYPES, FAKE_DATA };
