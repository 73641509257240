import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import BGImg from '@assets/img/bg/footer-bg.png';
import Logo from '@assets/img/logo/logo-white.png';
import BGFooterImg from '@assets/img/bg/footer.png';
import { connect } from 'react-redux';
import Swal from 'sweetalert2';
import { HomeActions } from '@adapters/redux/reducer/home';
import Zalo from '@components/Icons/zalo';
import Tripadvisor from '@components/Icons/tripadvisor';
import Play from '@assets/img/bg/play-button.png';
import ErrorBoundary from '@components/ErrorBoundary';
import { fullBackendPath } from '@core/helpers';
import { useTranslation } from 'react-i18next';

const Footer = (props) => {
  const { t, i18n } = useTranslation();
  const location = useLocation();

  const {
    video_banner,
    ownerInfo,
    sendingSubscribe,
    subscribeNoti,
    subscribeEmail,
    aboutRef,
  } = props;

  const {
    phone1,
    phone2,
    email1,
    email2,
    address,
    address_vi,
    socials,
    address_link,
  } = ownerInfo;

  const {
    link: videoLink,
    title: videoTitle,
    title_vi: videoTitleVie,
    video_background,
  } = video_banner;

  const facebookInfo = socials.find((s) => s.name === 'facebook');
  const zaloInfo = socials.find((s) => s.name === 'zalo');
  const instagramInfo = socials.find((s) => s.name === 'instagram');
  const youtubeInfo = socials.find((s) => s.name === 'youtube');
  const tripadvisorInfo = socials.find((s) => s.name === 'tripadvisor');

  const [yShow, setYShow] = useState(false);
  const [showMap, setShowMap] = useState(false);

  useEffect(() => {
    if (subscribeNoti)
      Swal.fire({
        title: subscribeNoti?.title,
        text: subscribeNoti?.text,
        icon: subscribeNoti?.type,
        confirmButtonText: 'OK',
        confirmButtonColor: '#2e1913',
      });
  }, [subscribeNoti]);

  const onClickSubscribe = () => {
    const email = document.getElementById('email2').value;
    if (email) {
      subscribeEmail(email);
    }
  };

  const phoneRegEx = /(?:[-+() ]*\d){10,13}/gm;
  const emailRegEx = /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/;

  return (
    <>
      {/* <section
        id="video"
        className="video-area pt-150 pb-150 p-relative"
        style={{
          backgroundImage: `url("${fullBackendPath(video_background?.path)}")`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'bottom',
          backgroundSize: 'cover',
        }}
      >
        <div className="content-lines-wrapper2">
          <div className="content-lines-inner2">
            <div className="content-lines2"></div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="s-video-wrap">
                <div className="s-video-content">
                  <Link
                    to="#"
                    className="popup-video"
                    onClick={() => setYShow(true)}
                  >
                    <img src={Play} alt="circle_right" />
                  </Link>
                </div>
              </div>
              <div className="section-title center-align text-center">
                <h2>
                  {i18n.language === 'en'
                    ? videoTitle || videoTitleVie
                    : videoTitleVie || videoTitle}
                </h2>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <footer className="footer-bg footer-p">
        <div
          className="footer-top pt-90 pb-60"
          style={{
            backgroundColor: '#644222',
            backgroundImage: `url(${BGImg})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center center',
            backgroundSize: '100% 100%',
            position: 'inherit',
          }}
        >
          <div className="container" style={{ position: 'inherit', zIndex: 2 }}>
            <div className="row justify-content-between">
              <div className="col-xl-4 col-lg-4 col-sm-6">
                <div className="footer-widget mb-30">
                  <div className="f-widget-title mb-30">
                    <img src={Logo} alt="aNhill VietNam" />
                  </div>
                  <div className="f-contact">
                    <ul className="list-style-none">
                      <li>
                        <i className="icon fal fa-phone"></i>
                        <span style={{ fontSize: 15 }}>
                          <div style={{ lineHeight: '25px' }}>
                            <a href={`tel:${phone1.match(phoneRegEx)?.[0]}`}>
                              {phone1}
                            </a>
                          </div>
                          <div style={{ lineHeight: '25px' }}>
                            <a href={`tel:${phone2.match(phoneRegEx)?.[0]}`}>
                              {phone2}
                            </a>
                          </div>
                        </span>
                      </li>
                      <li>
                        <i className="icon fal fa-envelope"></i>
                        <span style={{ fontSize: 15 }}>
                          <div style={{ lineHeight: '25px' }}>
                            <a href={`mailto:${email1.match(emailRegEx)?.[0]}`}>
                              {email1}
                            </a>
                          </div>
                          <div style={{ lineHeight: '25px' }}>
                            <a href={`mailto:${email2.match(emailRegEx)?.[0]}`}>
                              {email2}
                            </a>
                          </div>
                        </span>
                      </li>
                      <li>
                        <i className="icon fal fa-map-marker-check"></i>
                        <div
                          className="d-flex align-items-center"
                          style={{ height: 50 }}
                        >
                          <span
                            onClick={() => setShowMap(true)}
                            style={{
                              fontSize: 15,
                              lineHeight: '25px',
                              cursor: 'pointer',
                            }}
                          >
                            {address}
                          </span>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-2 col-lg-2 col-sm-6">
                <div className="footer-widget mb-30">
                  <div className="f-widget-title">
                    <h2>{t('Our links')}</h2>
                  </div>
                  <div className="footer-link">
                    <ul className="list-style-none">
                      <li>
                        <Link to="/">{t('Home')}</Link>
                      </li>
                      <li>
                        {location.pathname === '/' ? (
                          <a
                            className="link"
                            href={undefined}
                            onClick={() =>
                              aboutRef?.current?.scrollIntoView({
                                behavior: 'smooth',
                              })
                            }
                          >
                            {t('About us')}
                          </a>
                        ) : (
                          <Link to="/" state={{ goToAbout: true }}>
                            {t('About us')}
                          </Link>
                        )}
                      </li>
                      <li>
                        <Link to="/contact">{t('Contact us')}</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              {/* <div className="col-xl-2 col-lg-2 col-sm-6">
                <div className="footer-widget mb-30">
                  <div className="f-widget-title">
                    <h2>{t('Our Services')}</h2>
                  </div>
                  <div className="footer-link">
                    <ul className="list-style-none">
                      <li>
                        <Link to="/booking-guide">{t('Booking Guide')}</Link>
                      </li>
                      <li>
                        <Link to="/privacy-policy">{t('Privacy Policy')}</Link>
                      </li>
                      <li>
                        <Link to="/term-conditions">
                          {t('Term & Conditions')}
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div> */}
              <div className="col-xl-4 col-lg-4 col-sm-6">
                <div className="footer-widget mb-30">
                  <div className="f-widget-title">
                    <h2>{t('Subscribe to our newsletter')}</h2>
                  </div>
                  <div className="footer-link">
                    <p>{t('Subscribe for our latest updates and offers')}</p>
                    <div className="contact-form">
                      <div
                        className="subricbe p-relative"
                        data-animation="fadeInDown"
                        data-delay=".4s"
                      >
                        <input
                          type="text"
                          id="email2"
                          name="email2"
                          className="header-input"
                          placeholder={t('Your email')}
                          required
                        />
                        <button
                          disabled={sendingSubscribe}
                          className="btn header-btn"
                          onClick={onClickSubscribe}
                        >
                          {sendingSubscribe ? (
                            <i className="fa fa-spinner fa-spin" />
                          ) : (
                            <i className="fas fa-location-arrow"></i>
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="copyright-wrap">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-6">
                Copyright &copy; aNhill Vietnam 2023 . All rights reserved.
              </div>
              <div className="col-lg-6 col-md-6 text-right text-xl-right">
                <div className="footer-social">
                  {tripadvisorInfo && (
                    <Link to={tripadvisorInfo.link} target="_blank">
                      <Tripadvisor width={25} height={25} color="#ffffff" />
                    </Link>
                  )}
                  {facebookInfo && (
                    <Link to={facebookInfo.link} target="_blank">
                      <i className="fab fa-facebook-f"></i>
                    </Link>
                  )}
                  {/* {zaloInfo && (
                    <Link to={zaloInfo.link} target="_blank">
                      <Zalo width={40} height={40} color="#ffffff" />
                    </Link>
                  )} */}
                  {instagramInfo && (
                    <Link to={instagramInfo.link} target="_blank">
                      <i className="fab fa-instagram"></i>
                    </Link>
                  )}
                  {youtubeInfo && (
                    <Link to={youtubeInfo.link} target="_blank">
                      <i className="fab fa-youtube"></i>
                    </Link>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      {/* {yShow && (
        <>
          <div className="mfp-bg mfp-ready"></div>
          <div
            className="mfp-wrap mfp-close-btn-in mfp-auto-cursor mfp-ready"
            tabIndex="-1"
            style={{ overflow: 'hidden' }}
          >
            <div className="mfp-container mfp-s-ready mfp-iframe-holder">
              <div className="mfp-content">
                <div className="mfp-iframe-scaler">
                  <button
                    title="Close (Esc)"
                    type="button"
                    className="mfp-close"
                    onClick={() => setYShow(false)}
                  >
                    ×
                  </button>
                  <iframe
                    title="intro-iframe"
                    className="mfp-iframe"
                    src={videoLink}
                    frameBorder="0"
                    allowFullScreen=""
                  ></iframe>
                </div>
              </div>
              <div className="mfp-preloader">Loading...</div>
            </div>
          </div>
        </>
      )} */}
      {showMap && (
        <>
          <div className="mfp-bg mfp-ready"></div>
          <div
            className="mfp-wrap mfp-close-btn-in mfp-auto-cursor mfp-ready"
            tabIndex="-1"
            style={{ overflow: 'hidden' }}
          >
            <div className="mfp-container mfp-s-ready mfp-iframe-holder">
              <div className="mfp-content">
                <div className="mfp-iframe-scaler">
                  <button
                    title="Close (Esc)"
                    type="button"
                    className="mfp-close"
                    onClick={() => setShowMap(false)}
                  >
                    ×
                  </button>
                  <iframe
                    src={address_link}
                    width="100%"
                    height="100%"
                    style={{ frameBorder: 0 }}
                    allowFullScreen=""
                    loading={'lazy'}
                    referrerPolicy="no-referrer-when-downgrade"
                  ></iframe>
                </div>
              </div>
              <div className="mfp-preloader">Loading...</div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  video_banner: state.home.video_banner,
  ownerInfo: state.home.ownerInfo,
  sendingSubscribe: state.home.sendingSubscribe,
  subscribeNoti: state.home.subscribeNoti,
});

const mapDispatchToProps = (dispatch) => ({
  subscribeEmail: (email) => dispatch(HomeActions.subscribeEmail({ email })),
});

const withErrorBoundary = (props) => (
  <ErrorBoundary>
    <Footer {...props} />
  </ErrorBoundary>
);

export default connect(mapStateToProps, mapDispatchToProps)(withErrorBoundary);
